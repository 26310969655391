/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Environment } from '../app/model/environment';

const appUrl = `${window.location.protocol}//${window.location.host}`;

export const environment: Environment = {
  name: 'dev',
  production: false,
  debug: true,
  idleTimeout: 300, // minutes
  skipTimeoutCheck: true,
  oidc: {
    issuer: 'https://eva-wks-dev.eu.auth0.com/',
    clientId: 'XdVvsqOySypIbHbIQFLPdw1JYfZ9SqM6',
    logoutUrl: 'https://eva-wks-dev.eu.auth0.com/v2/logout',
    postLogoutRedirectUri: appUrl + '/unauthorized?reason=user-logout',
    redirectUri: appUrl,
    customQueryParams: {
      audience: appUrl + '/eva-api', // must match the configured audience in backend api
    },
  },
  apiUrl: appUrl + '/eva-api',
  appUrl: appUrl,
  publicStorageUrl: '/document/',
  publicImageStorageUrl: '/image/',
  additionalRestrictedUrls: [{ url: appUrl }],
  roleNamespace: 'https://eva.net',
  mapApiToken: 'XZxHIhz-NFbpug_lh3GDvTaKI_OYQvu2HzK7avr0QG8',
  geoapifyToken: '94738efae7b0402e8bc8a8c0d9eac11a',
  clientLogging: {
    enabled: true,
    verbose: true,
    apiToken: '872beda8462242a3949be59719f46b8a',
    reportLevel: 'debug',
  },
  pdd: {
    printFontSizePercent: 80,
  },
};
