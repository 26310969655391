/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {
  CriteriaInterface,
  IndicatorInterface,
  PrincipleInterface,
  StandardDefinitionInterface,
} from '@eva/certification/api';
import {
  AuditorRequest,
  Baseline,
  BaselineFragment,
  Certification,
  GrowthModel,
  GrowthModelFragment,
  Indication,
  IndicationFragment,
  MessageFragment,
  Role,
  Site,
  VerificationStatus,
} from '@eva/data-access/shared';
import { DialogService } from '@eva/ng-base';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import {
  AuditorRequestDisplayComponent,
  AuditorRequestDisplayDialogData,
} from './display/auditor-request-display.component';

import { AuthorizationService } from '../../../services/authorization.service';
import { AppState } from '../../../states/app.state';
import {
  AuditorRequestEditorComponent,
  AuditorRequestEditorDialogData,
} from './editor/auditor-request-editor.component';

export enum AUDITOR_REQUEST_TYPE {
  REQUEST = 'Request', // Rückfrage
  CONFIRMED_SAMPLE = 'Stichprobe (bestätigt)', // Stichprobe
  UNCONFIRMED_SAMPLE = 'Stichprobe (unbestätigt)', // Stichprobe
  UNCONFIRMED_FEEDBACK = 'Bestätigung ausstehend', // nur für Indikationen ohne Rückfragen
  CONFIRMED_FEEDBACK = 'Bestätigung', // nur für Indikationen ohne Rückfragen
}

// TODO!?
//| { id: string }; // for indicator (group)
export type possibleRelation =
  | BaselineFragment
  | GrowthModelFragment
  | IndicationFragment
  | Site;

export enum RELATION_TYPE {
  BASELINE = 'Baseline',
  GROWTHMODEL = 'GrowthModel',
  INDICATION = 'Indication',
  INDICATOR = 'Indicator',
  SITE = 'Site',
}
export class auditorRequestRelation {
  data: possibleRelation;
  data2: unknown;
  label: string;
  type: RELATION_TYPE;
}

export type AuditorRequestDialogData = {
  header: string;
  sampleMode: boolean;
  auditorComment: string;
  certification: Certification;
  auditorRequest?: AuditorRequest;
  showRelationSelection: boolean;
  showVerificationSelection: boolean;
  restrictedStatusSelection: boolean;
  collapsed: boolean;
  collapsable: boolean;
  allowEmptyTexts: boolean;

  dataChange$: Observable<AuditorRequestDialogData>;
  verificationStatus?: VerificationStatus;
  baselines?: { id: string }[];
  growthModels?: { id: string }[];
  sites?: { id: string }[];
  indication?: Indication;
  documents?: { id: string }[];
  submitMessage: EventEmitter<MessageFragment>;
};

@Injectable({
  providedIn: 'root',
})
export class AuditorRequestService implements OnDestroy {
  standardDefinitions: StandardDefinitionInterface[] = [];
  standardDefinition: StandardDefinitionInterface;
  currentRole: Role;
  _subscriptions = new Subscription();

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private authorizationService: AuthorizationService
  ) {
    this._subscriptions.add(
      this.store.select(AppState.standardDefinitions).subscribe((sdef) => {
        this.standardDefinitions = sdef;
      })
    );
    this.currentRole = this.store.selectSnapshot<Role>(
      (state: any) => state.app?.authenticatedUser?.role
    );
  }

  /**
   * helper function: feedback means an auditors answer to
   * an indication wich is not a request of type CAR, FAR but:
   * - a not yet finished answer to an indication
   * - an auditor request of status Compliant
   *
   * main reason for this function is to display an appropriate
   * label in messages, since a compliant auditor request without any
   * messages (previous states) is not a Corrective Action Request but
   * just a feedback
   *
   */
  static getAppropriateMessage(
    auditorRequest: AuditorRequest,
    sampleMode = false,
    action: 'SAVE' | 'UPDATE' = 'SAVE'
  ) {
    const auditorType = AuditorRequestService.getAuditorRequestType(
      auditorRequest,
      sampleMode
    );
    let message = $localize`:@@auditor-request.auditor-request-created:Die Rückfrage wurde gespeichert`;
    if (action === 'UPDATE') {
      message = $localize`:@@auditor-request.auditor-request-updated:Die Rückfrage wurde aktualisiert`;
    }
    if (
      [
        AUDITOR_REQUEST_TYPE.CONFIRMED_SAMPLE,
        AUDITOR_REQUEST_TYPE.UNCONFIRMED_SAMPLE,
      ].includes(auditorType)
    ) {
      message = $localize`:@@auditor-request.sample-created:Die Stichprobe wurde gespeichert`;
      if (action === 'UPDATE') {
        message = $localize`:@@auditor-request.sample-updated:Die Stichprobe wurde aktualisiert`;
      }
    }
    if (
      [
        AUDITOR_REQUEST_TYPE.CONFIRMED_FEEDBACK,
        AUDITOR_REQUEST_TYPE.UNCONFIRMED_FEEDBACK,
      ].includes(auditorType)
    ) {
      message = $localize`:@@auditor-request.feedback-created:Die Daten wurden gespeichert`;
      if (action === 'UPDATE') {
        message = $localize`:@@auditor-request.feedback-updated:Die Daten wurden aktualisiert`;
      }
    }
    return message;
  }

  static getAuditorRequestType(
    auditorRequest: AuditorRequest,
    sampleMode = false
  ): AUDITOR_REQUEST_TYPE {
    if (sampleMode || auditorRequest.sample) {
      return auditorRequest.verificationStatus === VerificationStatus.NONE
        ? AUDITOR_REQUEST_TYPE.UNCONFIRMED_SAMPLE
        : AUDITOR_REQUEST_TYPE.CONFIRMED_SAMPLE;
    }
    if (
      auditorRequest.indication &&
      (!auditorRequest.verificationStatus ||
        [VerificationStatus.NONE, VerificationStatus.COMPLIANT].includes(
          auditorRequest.verificationStatus
        ))
    ) {
      return auditorRequest.verificationStatus === VerificationStatus.NONE
        ? AUDITOR_REQUEST_TYPE.UNCONFIRMED_FEEDBACK
        : AUDITOR_REQUEST_TYPE.CONFIRMED_FEEDBACK;
    }
    return AUDITOR_REQUEST_TYPE.REQUEST;
  }

  static getAuditorRequestHeader(
    auditorRequest: AuditorRequest,
    sampleMode: boolean
  ): string {
    const auditorRequestType = AuditorRequestService.getAuditorRequestType(
      auditorRequest,
      sampleMode
    );
    const header = () => {
      switch (auditorRequestType) {
        case AUDITOR_REQUEST_TYPE.CONFIRMED_SAMPLE:
          return 'Stichprobe (bestätigt)';
        case AUDITOR_REQUEST_TYPE.UNCONFIRMED_SAMPLE:
          return 'Stichprobe (unbestätigt)';
        case AUDITOR_REQUEST_TYPE.CONFIRMED_FEEDBACK:
          return 'Bestätigung';
        case AUDITOR_REQUEST_TYPE.UNCONFIRMED_FEEDBACK:
          return 'Bestätigung (offen)';
        default:
          return 'Rückfrage';
      }
    };
    return header();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  sortRelations(
    type: RELATION_TYPE,
    availableItems: possibleRelation[],
    selectedItems: { id: string }[],
    standardVersion?: string
  ): {
    selectedItems: auditorRequestRelation[];
    availableItems: auditorRequestRelation[];
  } {
    const indicators: IndicatorInterface[] | undefined = undefined;

    const relations = this.getRelations(type, availableItems, indicators);
    return {
      selectedItems: relations.filter((r) =>
        selectedItems.map((si) => si.id).includes(r.data.id)
      ),
      availableItems: relations.filter(
        (r) => !selectedItems.map((si) => si.id).includes(r.data.id)
      ),
    };
  }

  setupData(
    certification: Certification,
    auditorRequest: AuditorRequest
  ): {
    selectedItems: auditorRequestRelation[];
    availableItems: auditorRequestRelation[];
  } | null {
    const selectedItems: auditorRequestRelation[] = [];
    const availableItems: auditorRequestRelation[] = [];

    if (certification && this.standardDefinitions.length > 0) {
      if (certification.baselines) {
        const baselineData = this.sortRelations(
          RELATION_TYPE.BASELINE,
          certification.baselines ?? [],
          auditorRequest?.baselines ?? []
        );
        selectedItems.push(...baselineData.selectedItems);
        availableItems.push(...baselineData.availableItems);
      }
      if (certification.growthModels) {
        const growthmodelData = this.sortRelations(
          RELATION_TYPE.GROWTHMODEL,
          certification.growthModels ?? [],
          auditorRequest?.growthModels ?? []
        );
        selectedItems.push(...growthmodelData.selectedItems);
        availableItems.push(...growthmodelData.availableItems);
      }
      if (certification.sites) {
        const siteData = this.sortRelations(
          RELATION_TYPE.SITE,
          certification.sites ?? [],
          auditorRequest?.sites ?? []
        );

        selectedItems.push(...siteData.selectedItems);
        availableItems.push(...siteData.availableItems);
      }
      if (auditorRequest?.indication || auditorRequest?.indicatorId) {
        if (certification.standardVersion) {
          const standard = this.standardDefinitions?.find((std) =>
            [std.version, std.revision].includes(certification.standardVersion)
          );

          const indicators = standard
            ? this.getIndicators(standard)
            : undefined;

          let myIndication: IndicationFragment | null = null;
          if (auditorRequest?.indicatorId) {
            myIndication =
              certification.indications?.find(
                (i: Indication) => i.indicatorId === auditorRequest.indicatorId
              ) ?? null;
          } else {
            myIndication =
              certification.indications?.find(
                (i: Indication) => i.id === auditorRequest.indication?.id
              ) ?? null;
          }
          if (myIndication) {
            const myRelation = this.getRelation(
              RELATION_TYPE.INDICATION,
              myIndication,
              indicators
            );
            selectedItems.push(myRelation);
          }
        }
      }
      if (certification.indications) {
        // const indicationData = this.sortRelations(
        //   RELATION_TYPE.INDICATION,
        //   [
        //     ...[...(certification.indications ?? [])].sort((a, b) =>
        //       a?.indicatorId.localeCompare(b?.indicatorId, 'de-DE-u-kn-true')
        //     ),
        //   ],
        //   auditorRequest?.indication !== undefined &&
        //     auditorRequest?.indication !== null
        //     ? [auditorRequest?.indication]
        //     : [],
        //   certification.standardVersion
        // );
        // selectedItems.push(...indicationData.selectedItems);
        // availableItems.push(...indicationData.availableItems);
      }
    } else {
      return null;
    }

    return { selectedItems, availableItems };
  }

  public getRelation(
    type: RELATION_TYPE,
    possibleRelation: possibleRelation,
    indicators?: IndicatorInterface[]
  ): auditorRequestRelation {
    const relation = { data: possibleRelation, type } as auditorRequestRelation;

    switch (type) {
      case RELATION_TYPE.GROWTHMODEL: {
        relation.label = `${(possibleRelation as GrowthModel).name}`;
        break;
      }
      case RELATION_TYPE.BASELINE: {
        relation.label = `${(possibleRelation as Baseline).name} `;
        break;
      }
      case RELATION_TYPE.SITE: {
        relation.label = `${(possibleRelation as Site).label}`;
        break;
      }
      case RELATION_TYPE.INDICATION: {
        const indicator = indicators?.find(
          (i) => i.identifier === (possibleRelation as Indication).indicatorId
        );
        relation.label = `${indicator?.nodeIndex}: ${indicator?.title}`;
        relation.data2 = indicator;
        break;
      }
      //TODO!?!?!
      // case RELATION_TYPE.INDICATOR:
      default:
        relation.label = `${type}`;
        break;
    }
    return relation;
  }

  public getRelations(
    type: RELATION_TYPE,
    relations: possibleRelation[],
    indicators?: IndicatorInterface[]
  ): auditorRequestRelation[] {
    return relations.map((r) => this.getRelation(type, r, indicators));
  }

  getIndicators(s: StandardDefinitionInterface): IndicatorInterface[] {
    const i = s.principles
      .map((p: PrincipleInterface) => [
        ...p.criterias.map((c: CriteriaInterface) => {
          const r = [...c.indicators];
          return r;
        }),
      ])
      .flatMap((i) => i)
      .flatMap((i) => i);
    return i.map((i) => ({ ...i, id: i.identifier }));
  }

  public getShortName(vs: VerificationStatus | null | undefined) {
    if (!vs) {
      return;
    }

    switch (vs) {
      case VerificationStatus.CLARIFICATION_REQUEST:
        return 'CL';
      case VerificationStatus.COMPLIANT:
        return 'C';
      case VerificationStatus.CORRECTIVE_ACTION_REQUEST:
        return 'CAR';
      // case VerificationStatus.ENDANGERED:
      //   return 'E';
      case VerificationStatus.FORWARD_ACTION_REQUEST:
        return 'FAR';
      case VerificationStatus.NON_COMPLIANT:
        return 'NC';
      case VerificationStatus.NONE:
        return 'XX';
    }
  }

  public getFullName(vs: VerificationStatus | null | undefined): string {
    if (!vs) {
      return '';
    }

    switch (vs) {
      case VerificationStatus.CLARIFICATION_REQUEST:
        return $localize`Rückfrage an den Standard (CL)`;
      case VerificationStatus.COMPLIANT:
        return $localize`Konform (C)`;
      case VerificationStatus.CORRECTIVE_ACTION_REQUEST:
        return $localize` Korrekturanfrage (CAR)`;
      // case VerificationStatus.ENDANGERED:
      //   return $localize`ENDANGERED (E)`;
      case VerificationStatus.FORWARD_ACTION_REQUEST:
        return $localize`Zukünftige Nachweisanfrage (FAR)`;
      case VerificationStatus.NON_COMPLIANT:
        return $localize`Nicht Konform (NC)`;
      case VerificationStatus.NONE:
        return $localize`Kein Status`;
      default:
        return vs;
    }
  }

  public basisFilter(): (value: AuditorRequest) => boolean {
    return (ar) => {
      return !(
        (ar.messages ?? []).length == 0 &&
        ar.verificationStatus === VerificationStatus.COMPLIANT &&
        ar.verificationStatus === ar.initialVerificationStatus &&
        !ar.sample
      );
    };
  }

  public filterRequests(
    auditorRequests: AuditorRequest[],
    filter: VerificationStatus | 'sample' | 'all',
    viewTarget: Role | 'PDD',
    relation?: RELATION_TYPE | RELATION_TYPE[] | null,
    relatedId?: string // show only AuditorRequests related to that ID
  ): AuditorRequest[] {
    let requests: AuditorRequest[] = [];
    requests = [...auditorRequests];

    requests = requests
      .filter((auditorRequest) => auditorRequest)
      .filter(
        (auditorRequest) =>
          !auditorRequest.sample || viewTarget !== Role.PROJECT_MANAGER
      )
      .filter(
        (auditorRequest) =>
          auditorRequest.verificationStatus === filter ||
          filter === 'all' ||
          auditorRequest.sample
      )
      // Einfache Ars ohne Nachrichten und ohne Statusänderung ausblenden,
      // außer für PDD!!
      .filter(
        (ar) =>
          !(
            (ar.messages ?? []).length == 0 &&
            ar.verificationStatus === VerificationStatus.COMPLIANT &&
            ar.verificationStatus === ar.initialVerificationStatus &&
            !ar.sample
          ) || viewTarget === 'PDD'
      )
      .filter((ar: AuditorRequest) => {
        if (!relation || relation?.length === 0) {
          return true;
        }

        if (Array.isArray(relation)) {
          return relation.some((r) => this.filterByRelation(ar, r, relatedId));
        } else {
          return this.filterByRelation(ar, relation, relatedId);
        }
      })
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

    const filteredRequests = requests.filter((r) => {
      if (r.verificationStatus === VerificationStatus.NONE) {
        return viewTarget === Role.AUDITOR || viewTarget === 'PDD';
      } else if (r.verificationStatus === VerificationStatus.COMPLIANT) {
        // dem PM nur anzeigen wenn es Nachrichten gab oder wenn
        // es eine bestätigte Stichprobe ist
        // Im PDD auch anzeigen wenn es ein AR ohne Nachrichten ist!

        return (
          viewTarget === Role.AUDITOR ||
          (r.messages?.length ?? 0) > 1 ||
          r.sample ||
          viewTarget === 'PDD'
        );
      } else {
        return true;
      }
    });
    return filteredRequests;
  }

  private filterByRelation(
    ar: AuditorRequest,
    relation: RELATION_TYPE,
    relatedId?: string
  ) {
    switch (relation) {
      case RELATION_TYPE.SITE:
        if (!ar.sites || ar.sites.length == 0) {
          return false;
        }
        if (!relatedId) {
          return ar.sites.length > 0;
        } else {
          return (
            ar.sites.length > 0 &&
            ar.sites.find((s: { id: string }) => s.id === relatedId) !==
              undefined
          );
        }

      case RELATION_TYPE.BASELINE:
        if (!ar.baselines || ar.baselines.length == 0) {
          return false;
        }
        if (!relatedId) {
          return ar.baselines.length > 0;
        } else {
          return (
            ar.baselines.length > 0 &&
            ar.baselines.find((s: { id: string }) => s.id === relatedId) !==
              undefined
          );
        }

      case RELATION_TYPE.GROWTHMODEL:
        if (!ar.growthModels || ar.growthModels.length == 0) {
          return false;
        }
        if (!relatedId) {
          return ar.growthModels.length > 0;
        } else {
          return (
            ar.growthModels.length > 0 &&
            ar.growthModels.find((s: { id: string }) => s.id === relatedId) !==
              undefined
          );
        }

      case RELATION_TYPE.INDICATOR:
      case RELATION_TYPE.INDICATION:
        return ar.indicatorId !== null || ar.indication !== null;
    }
  }

  public async openAuditorRequest(
    certification: Certification,
    auditorRequest: AuditorRequest | null | undefined,
    inputData: Partial<AuditorRequestDialogData>
  ) {
    // calculate edit mode
    const editMode =
      this.currentRole === Role.AUDITOR
        ? !!this.authorizationService.getAuditorEditMode(certification.status)
        : !!this.authorizationService.getEditMode(
            certification.status,
            this.currentRole
          );

    if (this.currentRole !== Role.AUDITOR) {
      this.dialogService.openDialogWithComponent<AuditorRequestDisplayDialogData>(
        AuditorRequestDisplayComponent,
        {
          header: auditorRequest?.sample ? 'Stichprobe' : 'Rückfrage',
          modal: true,
          style: { 'max-width': '60rem' },
          data: {
            certification: certification,
            auditorRequest: auditorRequest ?? ({} as AuditorRequest),
            showRelationSelection: inputData.showRelationSelection ?? true,
            collapsable: inputData.collapsable ?? false,
            collapsed: inputData.collapsed ?? false,
            editMode: editMode,
          },
        }
      );
    } else {
      const auditorRequestDialogData = this.mapToAuditorRequestEditDialogData(
        certification,
        auditorRequest,
        inputData,
        editMode
      );

      const sampleMode = auditorRequest
        ? auditorRequest.sample
        : inputData.sampleMode ?? false;
      this.dialogService.openDialogWithComponent<AuditorRequestEditorDialogData>(
        AuditorRequestEditorComponent,
        {
          header: sampleMode ? 'Stichprobe' : 'Rückfrage',
          modal: true,
          style: { 'max-width': '60rem' },
          data: {
            ...auditorRequestDialogData,
          },
        }
      );
    }
  }

  /**
   * Maps the data to the AuditorRequestEditorDialogData object needed by the AuditorRequestEditorDialogComponent
   *
   * @param certification Certification
   * @param auditorRequest AuditorRequest
   * @param inputData AuditorRequestDialogData input data object
   * @param editMode edit mode
   * @returns AuditorRequestEditorDialogData object
   */
  private mapToAuditorRequestEditDialogData(
    certification: Certification,
    auditorRequest: AuditorRequest | null | undefined,
    inputData: Partial<AuditorRequestDialogData>,
    editMode: boolean
  ): AuditorRequestEditorDialogData {
    let ar: AuditorRequest;

    if (!auditorRequest) {
      if (inputData.indication && !inputData.verificationStatus) {
        inputData.verificationStatus =
          VerificationStatus.CORRECTIVE_ACTION_REQUEST;
      }

      ar = {
        sample: inputData.sampleMode ?? false,
        reviewed: false,
        valid: false,
        auditorComment: '',
        internalAuditorRemarks: '',
        baselines: inputData.baselines,
        documents: inputData.documents ?? [],
        indication: inputData.indication ?? null,
        growthModels: inputData.growthModels,
        sites: inputData.sites,
        certificationId: certification?.id,
        verificationStatus: inputData.verificationStatus,
      } as AuditorRequest;
    } else {
      ar = auditorRequest;
    }

    return {
      certification: certification,
      indication: inputData.indication,
      showRelationSelection: inputData.showRelationSelection ?? true,
      showVerificationSelection: inputData.showVerificationSelection ?? true,
      sampleMode: inputData.sampleMode ?? false,
      restrictedStatusSelection: inputData.restrictedStatusSelection ?? false,
      allowEmptyTexts: inputData.allowEmptyTexts ?? false,
      auditorRequest: ar ?? ({} as AuditorRequest),
      editMode: editMode,
    };
  }

  public getLastMessageByStatus(
    vs: VerificationStatus,
    auditorRequests: AuditorRequest[],
    certification?: Certification
  ): {
    ar: AuditorRequest;
    relations: auditorRequestRelation[];
    text: string | null;
  }[] {
    //TODO evtl. Änderungen geplant: Zur Zeit ist der AR-Status immer der Status der letzten Message.
    return (
      auditorRequests
        ?.filter((ar) => ar.initialVerificationStatus === vs)
        .map((ar) => {
          return {
            ar,
            text: this.getLastMessageFromARByStatus(ar, vs),
            relations: certification
              ? this.setupData(certification, ar)?.selectedItems ?? []
              : [],
          };
        })
        .filter((i) => i) ?? []
    );
  }

  public getLastMessageFromARByStatus(
    ar: AuditorRequest,
    vs: VerificationStatus
  ): string | null {
    const sortedMessages =
      [...(ar.messages ?? [])].sort(
        (objB, objA) =>
          new Date(objA.createdAt).getTime() -
          new Date(objB.createdAt).getTime()
      ) ?? [];

    if (sortedMessages.length === 0 && ar.verificationStatus === vs) {
      return null;
    }
    let myText: string | null = null;

    // Ermittle die Nachricht mit dem ersten Status (VS), wenn zwischendurch kein andere Nachricht kam.
    sortedMessages.forEach((msg) => {
      // wenn anderer Status aber vorher bereits ein FAR existierte ist hier Schluss
      if (myText && msg.verificationStatus !== vs) {
        return myText;
      }
      if (msg.verificationStatus === vs) {
        myText = msg.text;
      }
    });
    return myText ?? ar.auditorComment;
  }
}
