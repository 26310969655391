/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { LoadingService } from '../services/loading.service';

/**
 * Interceptor for OAuth-restricted URLS.
 */
@Injectable({ providedIn: 'root' })
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private envService: EnvironmentService,
    private oauth: OAuthService,
    private loadingService: LoadingService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.startsWith(this.envService.getValue('apiUrl')) ||
      (this.envService.getValue('additionalRestrictedUrls') &&
        this._additionalUrlMatches(request.url))
    ) {
      const isFileRequest =
        request.body instanceof FormData || request.responseType === 'blob';
      if (!isFileRequest && request.body) {
        this.loadingService.setLoadingStatus(
          !isFileRequest ? (request.body as any)['operationName'] : 'File',
          !isFileRequest ? (request.body as any)['operationName'] : 'File',
          false,
          false,
          !isFileRequest
        );
      }

      const accessToken = this.oauth.getAccessToken();
      if (typeof accessToken === 'string') {
        return next
          .handle(
            request.clone({
              headers: request.headers.set(
                'Authorization',
                `Bearer ${accessToken}`
              ),
              withCredentials: false,
            })
          )
          .pipe(
            finalize(() =>
              this.loadingService.setLoadingStatus(
                !isFileRequest
                  ? (request.body as any)['operationName']
                  : 'File',
                !isFileRequest
                  ? (request.body as any)['operationName']
                  : 'File',
                true,
                true,
                !isFileRequest
              )
            )
          );
      }
    }

    return next.handle(request);
  }

  private _additionalUrlMatches(requestUrl: string): boolean {
    return this.envService
      .getValue('additionalRestrictedUrls')
      .some((item: any) => requestUrl.startsWith(item?.url));
  }
}
