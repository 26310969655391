/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * API Service
 *
 * TBD: move all GraphQL specific stuff to data-access/graphql lib?
 *
 */
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessagingService } from '../messaging.service';

import {
  CreateAuditorRequestGQL,
  CreateAuditorRequestMessageGQL,
  CreateBaselineGQL,
  CreateCertificationGQL,
  CreateGroupCertificationGQL,
  CreateGrowthModelGQL,
  CreateSiteGQL,
  DeleteAuditorRequestGQL,
  DeleteBaselineGQL,
  DeleteCertificationGQL,
  DeleteGrowthModelGQL,
  DeleteSiteGQL,
  GetBaselineGQL,
  GetGrowthModelGQL,
  GetScenarioDataGQL,
  GetScenarioSummariesGQL,
  GetTreeSpeciesGQL,
  ListCertificationsGQL,
  ListUsersGQL,
  LoadCertificationGQL,
  LoadFullCertificationsGQL,
  LoadGroupCertificationGQL,
  LoadSiteGQL,
  UpdateAuditDataGQL,
  UpdateAuditorRequestDocumentsGQL,
  UpdateAuditorRequestGQL,
  UpdateAuditorRequestMessageGQL,
  UpdateBaselineGQL,
  UpdateCertificationGQL,
  UpdateCertificationStatusGQL,
  UpdateCertifierGQL,
  UpdateGroupCertificationGQL,
  UpdateGroupCertificationStatusGQL,
  UpdateGroupCertifierGQL,
  UpdateGrowthModelGQL,
  UpdateIndicationGQL,
  UpdateSiteGQL,
} from '@eva/data-access/graphql';

import {
  ArBaselineDataInput,
  AuditorRequestDocumentsInput,
  AuditorRequestInput,
  AuditorRequestMessageInput,
  AuditorRequestUpdateInput,
  Certification,
  CertificationPartial,
  CreateArGrowthModelInput,
  CreateBaselineInput,
  CreateCertificationInput,
  CreateGroupCertificationInput,
  CreateGrowthModelInput,
  CreateSiteInput,
  DeleteAuditorRequestInput,
  GroupCertification,
  GrowthModel,
  ScenarioSummary,
  Site,
  StandardMethod,
  UpdateAuditDataInput,
  UpdateBaselineInput,
  UpdateCertificationInput,
  UpdateCertificationStatusInput,
  UpdateCertifierInput,
  UpdateGroupCertificationInput,
  UpdateGroupCertificationStatusInput,
  UpdateGrowthModelInput,
  UpdateIndicationInput,
  UpdateSiteInput,
  UserSimple,
} from '@eva/data-access/shared';

import { ArTreeSpeciesInterface } from '@eva/ar-services';
import { GraphQLError } from 'graphql';
import { SEVERITY } from '../../model/message';
import { LoadingService } from '../loading.service';

@Injectable()
export class CertificationApiService {
  constructor(
    private loadCertificationQuery: LoadCertificationGQL,
    private loadCertificationsQuery: LoadFullCertificationsGQL,
    private loadGroupCertificationQuery: LoadGroupCertificationGQL,
    private listCertificationsQuery: ListCertificationsGQL,
    private createCertificationMutation: CreateCertificationGQL,
    private updateCertificationMutation: UpdateCertificationGQL,
    private createGroupCertificationMutation: CreateGroupCertificationGQL,
    private updateGroupCertificationMutation: UpdateGroupCertificationGQL,
    private updateGroupCertificationStatusMutation: UpdateGroupCertificationStatusGQL,
    private updateCertificationStatusMutation: UpdateCertificationStatusGQL,
    private updateIndicationMutation: UpdateIndicationGQL,
    private deleteCertificationMutation: DeleteCertificationGQL,
    private listUsers: ListUsersGQL,
    private loadSiteQuery: LoadSiteGQL,
    private createSiteMutation: CreateSiteGQL,
    private updateSiteMutation: UpdateSiteGQL,
    private deleteSiteMutation: DeleteSiteGQL,
    private getBaselineQuery: GetBaselineGQL,
    private createBaselineMutation: CreateBaselineGQL,
    private updateBaselineMutation: UpdateBaselineGQL,
    private deleteBaselineMutation: DeleteBaselineGQL,
    private getGrowthModelQuery: GetGrowthModelGQL,
    private createGrowthModelMutation: CreateGrowthModelGQL,
    private updateGrowthModelMutation: UpdateGrowthModelGQL,
    private deleteGrowthModelMutation: DeleteGrowthModelGQL,
    private getTreesQuery: GetTreeSpeciesGQL,
    private messageService: MessagingService,
    private loadingService: LoadingService,
    private updateCertifierMutation: UpdateCertifierGQL,
    private updateGroupCertifierMutation: UpdateGroupCertifierGQL,
    private createAuditorRequestMutation: CreateAuditorRequestGQL,
    private updateAuditorRequestMutation: UpdateAuditorRequestGQL,
    private deleteAuditorRequestMutation: DeleteAuditorRequestGQL,
    private createAuditorRequestMessageMutation: CreateAuditorRequestMessageGQL,
    private updateAuditorRequestMessageMutation: UpdateAuditorRequestMessageGQL,
    private updateAuditDataMutation: UpdateAuditDataGQL,
    private getScenarioSummariesQuery: GetScenarioSummariesGQL,
    private getScenarioDataQuery: GetScenarioDataGQL,
    private updateAuditorRequestDocumentsGQL: UpdateAuditorRequestDocumentsGQL
  ) {}

  /**
   * this error handlers shows an error message via MessagingService
   * and returns an empty array which means dispatched actions and subscribers
   * will not get the error anymore
   *
   * This is mainly for convenience (reducing error handling in components)
   * but there might be use cases where this is not wanted, then this
   * error handler should not be used
   */
  private errorHandler(err: any) {
    if (environment.debug && (err.graphQLErrors || err.networkError)) {
      err.graphQLErrors.forEach((error: GraphQLError) => {
        console.debug('graphQLError', error);
        if (error.message) {
          if (error.extensions?.status && error.extensions?.status === 409) {
            this.messageService.confirmOnly(
              'Warnung',
              error.message,
              () => console.log(),
              SEVERITY.WARN
            );
          } else {
            this.showError('Fehler', error.message, false);
          }
        }
      });
      if (err.networkError?.statusText) {
        this.showError('Fehler', err.networkError.statusText);
      }
      if (err.networkError?.error?.errors?.length > 0) {
        err.networkError.error.errors.forEach((e: Error) => {
          console.debug('Network Error:' + e.message);
        });
      }
      throw err;
    } else if (environment.debug && err.message) {
      this.showError('Client-Fehler', err.message);
    } else {
      throw new Error(
        'Fehler: die gewünschte Aktion konnte nicht durchgeführt werden. Bitte informieren Sie das WKS Sekreteriat!'
      );
    }
    return of(null);
  }

  private extractData<T>(result: ApolloQueryResult<T> | FetchResult<T>) {
    if (result.data === undefined || result.errors?.length) {
      if (result.errors) {
        this.showError(
          'Fehler: keine Daten verfügbar!',
          result.errors[0].message
        );
      }
      return null;
    } else {
      return result.data;
    }
  }

  private showError(headline: string, message: string, showNumber = true) {
    this.messageService.error(headline, message, showNumber);
  }

  loadTrees(): Observable<{ getTreeSpecies: ArTreeSpeciesInterface[] | null }> {
    return this.getTreesQuery.watch().valueChanges.pipe(
      map((result) => this.extractData(result)),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ getTreeSpecies: ArTreeSpeciesInterface[] | null }>;
  }

  listCertifications(): Observable<{
    certifications: Certification[] | null;
  }> {
    return this.listCertificationsQuery.watch().valueChanges.pipe(
      map((result) => this.extractData(result)),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ certifications: Certification[] | null }>;
  }

  loadCertifications(): Observable<{
    loadCertifications: CertificationPartial[];
  }> {
    return this.loadCertificationsQuery.watch().valueChanges.pipe(
      map((result) => this.extractData(result)),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ loadCertifications: CertificationPartial[] }>;
  }

  loadCertification(
    id: string
  ): Observable<{ certification: Certification } | null> {
    const key = this.loadingService.start('Lade Zertifizierungsdaten');
    return this.loadCertificationQuery.watch({ id }).valueChanges.pipe(
      map((result) => this.extractData(result)),
      tap(() => this.loadingService.loaded(key)),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ certification: Certification } | null>;
  }

  loadGroupCertification(
    id: string
  ): Observable<{ groupCertification: GroupCertification } | null> {
    const key = this.loadingService.start('Lade Zertifizierungsgruppe');
    return this.loadGroupCertificationQuery.watch({ id }).valueChanges.pipe(
      map((result) => this.extractData(result)),
      tap(() => this.loadingService.loaded(key)),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{
      groupCertification: GroupCertification;
    } | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  createCertification(
    input: CreateCertificationInput
  ): Observable<Certification | null> {
    return this.createCertificationMutation.mutate({ input }).pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.createCertification),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateCertification(
    input: UpdateCertificationInput
  ): Observable<Certification | null> {
    console.log('updateCertification', input);

    return this.updateCertificationMutation.mutate({ input }).pipe(
      map((result) => result.data?.updateCertification ?? null),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  createGroupCertification(
    input: CreateGroupCertificationInput
  ): Observable<GroupCertification | null> {
    return this.createGroupCertificationMutation.mutate({ input }).pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.createGroupCertification),
      catchError((error) => this.errorHandler(error))
    ) as Observable<GroupCertification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateGroupCertification(
    input: UpdateGroupCertificationInput
  ): Observable<GroupCertification | null> {
    return this.updateGroupCertificationMutation.mutate({ input }).pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.updateGroupCertification),
      catchError((error) => this.errorHandler(error))
    ) as Observable<GroupCertification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateGroupCertificationStatus(
    input: UpdateGroupCertificationStatusInput
  ): Observable<GroupCertification | null> {
    return this.updateGroupCertificationStatusMutation.mutate({ input }).pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.updateGroupCertificationStatus),
      catchError((error) => this.errorHandler(error))
    ) as Observable<GroupCertification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateAuditData(
    input: UpdateAuditDataInput
  ): Observable<Certification | null> {
    console.log('updateCertification', input);

    return this.updateAuditDataMutation.mutate({ input }).pipe(
      map((result) => result?.data?.updateAuditData),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateCertificationStatus(
    input: UpdateCertificationStatusInput
  ): Observable<{ updateCertificationStatus: Certification | null }> {
    return this.updateCertificationStatusMutation.mutate({ input }).pipe(
      map((result) => result.data),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ updateCertificationStatus: Certification | null }>;
  }

  /**
   * Update certifier for certification
   */
  updateCertifier(input: UpdateCertifierInput): Observable<{
    certification: Certification | null;
  } | null> {
    return this.updateCertifierMutation.mutate({ input }).pipe(
      map((result) => result.data ?? null),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{
      certification: Certification | null;
    } | null>;
  }

  /**
   * Update certifier for group certification
   */
  updateGroupCertifier(input: UpdateCertifierInput): Observable<{
    groupCertification: GroupCertification | null;
  } | null> {
    return this.updateGroupCertifierMutation.mutate({ input }).pipe(
      map((result) => result.data ?? null),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{
      groupCertification: GroupCertification | null;
    } | null>;
  }

  deleteCertification(
    id: string
  ): Observable<{ deleteCertification: Certification } | null> {
    return this.deleteCertificationMutation.mutate({ id }).pipe(
      map((result) => result.data ?? null),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ deleteCertification: Certification } | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateIndication(
    input: UpdateIndicationInput
  ): Observable<Certification | null> {
    console.log('updateIndication', input);
    return this.updateIndicationMutation.mutate({ input }).pipe(
      map((result) => result.data?.updateIndication),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  loadUsers(): Observable<UserSimple[] | null> {
    return this.listUsers.watch().valueChanges.pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.users),
      catchError((error) => this.errorHandler(error))
    ) as Observable<UserSimple[] | null>;
  }

  loadSite(id: string): Observable<Site | null> {
    return this.loadSiteQuery.watch({ id }).valueChanges.pipe(
      map((result) => result.data.site),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Site | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  createSite(input: CreateSiteInput[]): Observable<Certification | null> {
    return this.createSiteMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.createSite),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  /**
   *
   * @param input
   * @returns
   */
  updateSite(input: UpdateSiteInput): Observable<Certification | null> {
    return this.updateSiteMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.updateSite),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  deleteSite(id: string): Observable<Certification | null> {
    console.log('deleteSite', id);

    return this.deleteSiteMutation.mutate({ id }).pipe(
      map((result) => result.data?.deleteSite),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  getScenarioSummaries(
    groupId: string,
    scenarioType: 'Baseline' | 'GrowthModel'
  ): Observable<ScenarioSummary[] | null> {
    return this.getScenarioSummariesQuery
      .watch({ id: groupId, scenarioType })
      .valueChanges.pipe(
        map((result) => {
          return (result.data.scenarioSummaries as ScenarioSummary[]) ?? [];
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  getBaseline(method: StandardMethod, id: string) {
    return this.getBaselineQuery.watch({ method, id }).valueChanges.pipe(
      map((result) => {
        console.log('getBaseline', result);
        return result.data;
      }),
      catchError((error) => this.errorHandler(error))
    );
  }

  createBaseline(input: CreateBaselineInput): Observable<Certification | null> {
    return this.createBaselineMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.createBaseline),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  updateBaseline(
    input: UpdateBaselineInput,
    methodInput?: ArBaselineDataInput
  ): Observable<Certification | null> {
    return this.updateBaselineMutation.mutate({ input, methodInput }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.updateBaseline),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  deleteBaseline(input: UpdateBaselineInput): Observable<Certification | null> {
    return this.deleteBaselineMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.deleteBaseline),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  getGrowthModel(
    method: StandardMethod,
    id: string
  ): Observable<{ growthModel: GrowthModel } | null> {
    return this.getGrowthModelQuery.watch({ method, id }).valueChanges.pipe(
      map((result) => result.data),
      catchError((error) => this.errorHandler(error))
    ) as Observable<{ growthModel: GrowthModel } | null>;
  }

  createGrowthModel(
    input: CreateGrowthModelInput
  ): Observable<Certification | null> {
    return this.createGrowthModelMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.createGrowthModel),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  updateGrowthModel(
    input: UpdateGrowthModelInput,
    methodInput?: CreateArGrowthModelInput
  ): Observable<Certification | null> {
    return this.updateGrowthModelMutation.mutate({ input, methodInput }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.updateGrowthModel),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  deleteGrowthModel(
    input: UpdateGrowthModelInput
  ): Observable<Certification | null> {
    return this.deleteGrowthModelMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.deleteGrowthModel),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  createAuditorRequest(
    input: AuditorRequestInput
  ): Observable<Certification | null> {
    const inputData = this.prepareInput(input);

    return this.createAuditorRequestMutation.mutate({ input: inputData }).pipe(
      map((result) => result.data?.createAuditorRequest),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  updateAuditorRequest(
    input: AuditorRequestUpdateInput
  ): Observable<Certification | null> {
    const inputData = this.prepareInput(input);
    return this.updateAuditorRequestMutation.mutate({ input: inputData }).pipe(
      map((result) => result.data?.updateAuditorRequest),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  private prepareInput(input: any) {
    const inputData: any = { ...input };
    for (const [key, value] of Object.entries(input)) {
      if (
        ['baselines', 'indications', 'growthModels', 'sites'].includes(key) &&
        inputData[key]?.length
      ) {
        inputData[key] = (value as Array<any>).map(({ id }) => ({ id }));
      }
    }

    return inputData;
  }

  deleteAuditorRequest(
    input: DeleteAuditorRequestInput
  ): Observable<Certification | null> {
    return this.deleteAuditorRequestMutation.mutate({ input }).pipe(
      map((result) => result?.data?.deleteAuditorRequest),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | null>;
  }

  createAuditorRequestMessage(
    input: AuditorRequestMessageInput
  ): Observable<Certification | null> {
    return this.createAuditorRequestMessageMutation
      .mutate({ input: input })
      .pipe(
        map((result) => result.data?.createAuditorRequestMessage),
        catchError((error) => this.errorHandler(error))
      ) as Observable<Certification | null>;
  }

  updateAuditorRequestMessage(
    input: AuditorRequestMessageInput
  ): Observable<Certification | null> {
    return this.updateAuditorRequestMessageMutation
      .mutate({ input: input })
      .pipe(
        map((result) => result.data?.updateAuditorRequestMessage),
        catchError((error) => this.errorHandler(error))
      ) as Observable<Certification | null>;
  }

  updateAuditorRequestDocuments(
    input: AuditorRequestDocumentsInput
  ): Observable<Certification | undefined> {
    return this.updateAuditorRequestDocumentsGQL.mutate({ input: input }).pipe(
      map((result) => result.data?.updateAuditorRequestDocuments),
      catchError((error) => this.errorHandler(error))
    ) as Observable<Certification | undefined>;
  }
}
