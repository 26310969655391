/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function editorMinLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    // Remove html from string
    const valueWithoutHtml =
      value?.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ') ?? '';

    // Check length and return error
    return valueWithoutHtml.length < minLength ? { minLength: true } : null;
  };
}
