/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { DecimalPipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CertificationProcessService } from '@eva/certification/service';
import {
  Certification,
  CertificationPartial,
  StandardMethod,
  VerificationStatus,
} from '@eva/data-access/shared';
import { AuditorRequestService } from '../components/shared/auditor-request/auditor-request.service';

export interface TextTemplateVariable {
  name: string;
  variable: string;
}
@Injectable({
  providedIn: 'root',
})
export class TextTemplateService {
  constructor(
    private auditorRequestService: AuditorRequestService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  // Use a Template-Text and replace all variables with the values from project/certification
  //@return textTemplateContent with replaced variables
  public replaceVariablesInTextTemplate(
    textTemplateContent: string,
    certification: Certification | CertificationPartial | undefined | null
  ): string {
    if (!certification) {
      return '';
    }
    const isFullC = (
      o: Certification | CertificationPartial
    ): o is Certification => o && 'sites' in o;
    const mapObj: { [id: string]: string } = {
      PROJECTNAME: certification.project?.name ?? '',
      AUDITORNAME: certification.certifier?.organisation?.name ?? '',
      REGIONNAME: certification.growthRegion?.name ?? '',
      METHODNAME:
        certification.method === StandardMethod.AR
          ? 'Methode: 01 Wald-Wiederaufbau'
          : certification.method === StandardMethod.IFM
          ? 'Methode: 02 Waldumbau'
          : 'Methode: 03 Klimaoptimiertes Forstbetriebsmanagement',
      TOTALAREA:
        new DecimalPipe(this.locale).transform(
          (certification.totalArea ?? 0) / 10000,
          '1.0-1'
        ) ?? '',
      FARCOUNT: isFullC(certification)
        ? this.auditorRequestService
            .getLastMessageByStatus(
              VerificationStatus.FORWARD_ACTION_REQUEST,
              certification.auditorRequests,
              certification
            )
            .length.toString() ?? ''
        : '',
      CARCOUNT: isFullC(certification)
        ? this.auditorRequestService
            .getLastMessageByStatus(
              VerificationStatus.CORRECTIVE_ACTION_REQUEST,
              certification.auditorRequests,
              certification
            )
            .length.toString() ?? ''
        : '',
      // ...

      KLIMAWIRKUNG:
        new DecimalPipe(this.locale).transform(
          CertificationProcessService.calculateShares(certification)
            .totalCo2Storage,
          '1.0-1'
        ) ?? '',
    };
    const regex = new RegExp(/%([^%.*%]+)%/gm, 'g');

    textTemplateContent = textTemplateContent.replace(regex, (_, c) => {
      // if (!mapObj[c]) {
      //   console.warn('Variable not found: ' + c);
      // }
      return mapObj[c] ?? '';
    });
    return textTemplateContent;
  }

  //@return all possible text-template-variables
  public get Variables(): TextTemplateVariable[] {
    return [
      { name: 'Name des Projektes', variable: '%PROJECTNAME%' },
      { name: 'Auditor-Name', variable: '%AUDITORNAME%' },
      { name: 'Wachstumsregion', variable: '%REGIONNAME%' },
      { name: 'Angewandte Zertifizierungsmethode', variable: '%METHODNAME%' },
      { name: 'Gesamtfläche in ha', variable: '%TOTALAREA%' },
      { name: 'Anzahl CARs', variable: '%CARCOUNT%' },
      { name: 'Anzahl FARs', variable: '%FARCOUNT%' },
      {
        name: 'Klimawirkung (inkl. Puffer und eva-Gebühr)',
        variable: '%KLIMAWIRKUNG%',
      },
    ];
  }
}
