/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { CommonModule, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import '@angular/localize/init';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Role } from '@eva/certification/api';
import {
  OrganisationFragment,
  OrganisationType,
  UpdateUserInput,
  User,
} from '@eva/data-access/shared';
import { DialogData } from '@eva/ng-base';
import { Select, Store } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription, first } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoadAuthenticatedUserAction, LoadUsersAction } from '../../../actions';
import {
  LoadUserAction,
  UpdateAuthenticatedUserAction,
  UpdateUserAction,
} from '../../../actions/userActions';
import { MessagingService } from '../../../services/messaging.service';
import {
  CustomValidators,
  PASSWORD_POLICY_LEVEL,
} from '../../../shared/custom-validators';
import { AppState } from '../../../states/app.state';
import { PrimengModule } from '../../shared/primeng.module';
import { SharedComponentsModule } from '../../shared/shared-components.module';

interface UserForm {
  title: FormControl<string | null>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
  password: FormControl<string | null>;
  passwordConfirmation: FormControl<string | null>;
  remarks: FormControl<string | null>;
  agbAccepted: FormControl<boolean | null>;
  role: FormControl<string | null>;
}

export interface EditUserDialogData {
  editAuthenticatedUser: boolean;
}

@Component({
  selector: 'eva-edit-user',
  templateUrl: './edit.user.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    SharedComponentsModule,
    RouterModule,
  ],
})
export class EditUserComponent implements OnInit, OnChanges {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;
  @Select(AppState.currentUser) user$: Observable<User>;
  @Select(AppState.users) users$: Observable<User[]>;

  // Run in dialog for authenticated user (editAuthenticatedUser = true)
  // or in page for current user (editAuthenticatedUser = false)
  @Input() editAuthenticatedUser: boolean;
  @Output() done = new EventEmitter<boolean>();

  userForm: FormGroup<UserForm>;
  formUser: User;

  data: UpdateUserInput & { passwordConfirmation?: string };

  isAdmin = false;
  selectedRole: Role;
  consultantOrganisations: OrganisationFragment[] = [];

  userId: string;

  passwordConfirmationRequired = false;
  passwordPolicyLevel: PASSWORD_POLICY_LEVEL = PASSWORD_POLICY_LEVEL.GOOD;
  passwordHint = '';

  showPasswordFields = false;

  agbFile = 'AGB-230301.pdf';

  roles: { role: string; value: string }[] = [];

  protected readonly Role = Role;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService,
    public location: Location,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<DialogData<EditUserDialogData>>
  ) {
    this.initForm();
    this.setPasswordHint();

    if (this.config.data) {
      this.config.data.actions = [
        {
          text: 'Schließen',
          type: 'outlined',
          i18nLabel: '@@close',
          icon: {
            name: 'pi pi-times',
          },
          action: () => {
            this.cancel();
            return Subscription.EMPTY;
          },
        },
        {
          text: 'Speichern',
          i18nLabel: '@@save',
          icon: {
            name: 'pi pi-check',
          },
          disable: () => {
            return this.userForm;
          },
          action: () => {
            return this.submitForm();
          },
        },
      ];
    }

    if (config.data) {
      this.editAuthenticatedUser = config.data.editAuthenticatedUser || false;
    }
  }

  async ngOnInit() {
    // Subscription for currentUser change, to specify the user to be edited
    this.user$?.subscribe((currentUser) => {
      if (!this.editAuthenticatedUser) {
        this.setUser(currentUser);
      }
    });

    // Subscription for authenticatedUser change, to specify the user to be edited
    this.authenticatedUser$?.subscribe((authenticatedUser) => {
      if (this.editAuthenticatedUser) {
        this.setUser(authenticatedUser);
        this.showPasswordFields = true;
      }
      if (authenticatedUser) {
        this.isAdmin = authenticatedUser.role === Role.ADMIN;
        if (this.isAdmin) {
          this.users$.pipe(first()).subscribe((users) => {
            this.consultantOrganisations = this.distinct(
              users
                .filter((u: { role: Role }) => u.role === Role.CONSULTANT)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((user) => user.organisation!) ?? [],
              (a, b) => a?.id === b?.id
            ).filter(
              (organisation) =>
                organisation.id === authenticatedUser.organisation?.id ||
                this.isAdmin
            );
            this.consultantOrganisations.unshift({
              name: 'Kein Berater',
              id: '',
              ownerId: '',
              type: OrganisationType.ORGANISATION,
            });
          });
          this.store.dispatch(new LoadUsersAction());
          this.roles = [
            {
              role: $localize`:@@FOREST_OWNER:Waldbesitzer`,
              value: Role.PROJECT_MANAGER,
            },
            {
              role: $localize`:@@CONSULTANT:Projektberater`,
              value: Role.CONSULTANT,
            },
          ];
        } else if (authenticatedUser.organisation) {
          this.consultantOrganisations = [authenticatedUser.organisation];
        }
      }
    });

    // Get user ID from URL
    if (!this.editAuthenticatedUser) {
      const currentUserId = this.route.snapshot.paramMap.get('userId');
      if (currentUserId) {
        this.store.dispatch(new LoadUserAction(currentUserId));
      }
    }

    // Subscription for form changes
    this.userForm.valueChanges.pipe(debounceTime(400)).subscribe({
      next: (change) => {
        this.data = { ...this.data, ...change } as UpdateUserInput & {
          passwordConfirmation?: string;
        };
        if (!this.data.password) {
          delete this.data.password;
        }
      },
      error: (err) => console.log(err),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Switch between current and authenticated user, depending on the status of editAuthenticatedUser
    if (changes.editAuthenticatedUser) {
      if (changes.editAuthenticatedUser.currentValue) {
        this.store.dispatch(new LoadAuthenticatedUserAction());
      } else {
        const userId = this.route.snapshot.paramMap.get('userId');
        if (userId) {
          this.store.dispatch(new LoadUserAction(userId));
        }
      }
    }
  }

  cancel() {
    this.userForm.reset();
    this.done.emit(false);
    this.ref.close();
    if (!this.editAuthenticatedUser) {
      this.router.navigateByUrl('/users');
    }
  }

  distinct<T>(data: T[], comparableFunction: (a: T, b: T) => boolean): T[] {
    return data?.filter((thing, i, arr) => {
      const found = arr.find((t) => comparableFunction(t, thing));
      return found ? arr?.indexOf(found) === i : false;
    });
  }

  initForm() {
    this.userForm = this.formBuilder.group<UserForm>({
      title: new FormControl(null),
      firstName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(null, CustomValidators.germanPhone),
      password: new FormControl(null),
      passwordConfirmation: new FormControl(null),
      remarks: new FormControl(null),
      agbAccepted: new FormControl(null),
      role: new FormControl<string>(''),
    });

    // Set password validators
    this.userForm.valueChanges.subscribe((formValue) => {
      const passwordControl = this.userForm?.get('password');
      const passwordConfirmationControl = this.userForm?.get(
        'passwordConfirmation'
      );
      if (formValue.password) {
        passwordControl?.setValidators([
          CustomValidators.passwordStrength({
            policyLevel: this.passwordPolicyLevel,
          }),
        ]);
        passwordConfirmationControl?.setValidators([Validators.required]);
        this.userForm?.setValidators([CustomValidators.passwordConfirmation()]);
        this.passwordConfirmationRequired = true;
      } else {
        passwordControl?.clearValidators();
        passwordConfirmationControl?.clearValidators();
        this.userForm?.clearValidators();
        this.passwordConfirmationRequired = false;
      }
      passwordControl?.updateValueAndValidity({ emitEvent: false });
      passwordConfirmationControl?.updateValueAndValidity({ emitEvent: false });
      this.userForm?.updateValueAndValidity({ emitEvent: false });
    });
  }

  setUser(user: User) {
    if (!user) {
      return;
    }
    this.formUser = user;
    this.userForm.patchValue({
      ...user,
      role: user.role,
    });
    this.data = { ...this.data, ...this.userForm.value } as UpdateUserInput & {
      passwordConfirmation?: string;
    };
    this.userId = user.id;
    this.selectedRole = user.role;
  }

  submitForm(): Subscription {
    Object.values(this.userForm.controls).forEach((key) => key.markAsDirty());
    if (this.userForm.invalid) {
      this.messagingService.warn(
        $localize`:@@error:Fehler`,
        $localize`:FormValidation@@missing-input:Bitte überprüfen Sie Ihre Eingaben!`
      );
      return Subscription.EMPTY;
    }
    if (!this.data) {
      return Subscription.EMPTY;
    }

    // Set update data
    delete this.data.passwordConfirmation;
    const updateUserInput: UpdateUserInput = {
      ...this.data,
      id: this.userId,
    } as UpdateUserInput;

    // Set action
    let action = new UpdateUserAction(updateUserInput);
    if (this.editAuthenticatedUser) {
      action = new UpdateAuthenticatedUserAction(updateUserInput);
    }
    return this.store.dispatch(action).subscribe(() => {
      this.userForm.patchValue({ password: null, passwordConfirmation: null });
      this.messagingService.success(
        '',
        $localize`:@@user-updated:Der User wurde aktualisiert`
      );
      this.done.emit(false);
      this.ref.close();
    });
  }

  setPasswordHint(
    passwordPolicyLevel: PASSWORD_POLICY_LEVEL = this.passwordPolicyLevel
  ) {
    const hints = {
      [PASSWORD_POLICY_LEVEL.NONE]: $localize`:@@password-policy-hint-None:Bitte Passwort eingeben.`,
      [PASSWORD_POLICY_LEVEL.LOW]: $localize`:@@password-policy-hint-Low:Bitte Passwort mit mind. 6 Zeichen eingeben.`,
      [PASSWORD_POLICY_LEVEL.FAIR]: $localize`:@@password-policy-hint-Fair:Bitte Passwort mit mind. 8 Zeichen und einem Kleinbuchstaben, einem Großbuchstaben und einer Zahl eingeben.`,
      [PASSWORD_POLICY_LEVEL.GOOD]: $localize`:@@password-policy-hint-Good:Bitte Passwort mit mindestens 8 Zeichen, darunter mindestens 3 der folgenden 4 Arten von Zeichen: ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl, ein Sonderzeichen (wie !@#$%^&*).`,
      [PASSWORD_POLICY_LEVEL.EXCELLENT]: $localize`:@@password-policy-hint-Excellent:Mindestens 10 Zeichen, darunter mindestens 3 der folgenden 4 Arten von Zeichen: ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl, ein Sonderzeichen (wie !@#$%^&*). Nicht mehr als 2 identische Zeichen in einer Reihe (z. B. 111 ist nicht erlaubt).`,
    };
    this.passwordHint = hints[passwordPolicyLevel];
  }
}
