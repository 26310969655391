/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  AuditorRequestFragment,
  CertificationFragment,
  IndicationFragment,
} from '@eva/data-access/shared';
import { CertificationProcessService } from './certification-process.service';

import { StandardDefinitionService } from './standard-definition.service';

import {
  AuditorType,
  CertificationInterface,
  CertificationStatus,
  CriteriaInterface,
  EvidenceType,
  IndicationInterface,
  IndicatorInterface,
  InputType,
  PrincipleInterface,
  Role,
  StandardDefinitionInterface,
  StandardMethod,
  StandardVersion,
  VerificationStatus,
} from '@eva/certification/api';

export type CertificationValidationErrors = {
  noCertification?: boolean;
  noBaselines?: boolean;
  invalidBaselines?: string[];
  noSites?: boolean;
  siteWithoutGrowthModel?: boolean;
  siteWithoutBaseline?: boolean;
  unassignedSites?: string[];
  noGrowthModels?: boolean;
  invalidGrowthModels?: string[];
  noIndications?: boolean;
  invalidIndications?: string[];
  invalidAuditorRequests?: boolean;
} | null;

/**
 * export type Certification =
  | (CertificationInterface & DeepRequired<{ sites: Site[] }>)
  | (CertificationFragment & DeepRequired<{ sites: Site[] }>);
 */
export type Certification = CertificationInterface | CertificationFragment;

export class ValidationService {
  /**
   * check if a certification is valid according to
   * th provided standard
   */
  static isValidCertification(
    certification: Certification,
    standard: StandardDefinitionInterface
  ): boolean {
    return (
      ValidationService.validateIndications(certification, standard) === null &&
      ValidationService.validateAuditorRequests(certification) === null &&
      ValidationService.validateBaselines(certification) === null &&
      ValidationService.validateGrowthModel(certification) === null
    );
  }

  /**
   * get all validation errors for a certification
   * or a boolean for a quick test
   */
  static validateCertification(
    certification: CertificationInterface,
    stopAtFirstError: boolean
  ): boolean;

  static validateCertification(
    certification: CertificationInterface,
    stopAtFirstError: boolean,
    skipIndications: boolean
  ): boolean;

  static validateCertification(
    certification: CertificationInterface
  ): CertificationValidationErrors;

  static validateCertification(
    certification: CertificationInterface,
    stopAtFirstError?: boolean,
    skipIndications?: boolean
  ): CertificationValidationErrors | boolean {
    const empty = (o: object | null) =>
      o === null || Object.keys(o).length === 0;
    const siteErrors: CertificationValidationErrors = {};
    if (!certification.sites || certification.sites?.length < 1) {
      if (stopAtFirstError) {
        return false;
      }
      siteErrors['noSites'] = true;
    }
    certification.sites?.forEach((site) => {
      if (certification.method !== StandardMethod.IFM_DE_OCELL) {
        if (!site.growthModelId) {
          siteErrors.siteWithoutGrowthModel = true;
        }
        if (!site.baselineId) {
          siteErrors['siteWithoutBaseline'] = true;
        }
      }
    });
    if (stopAtFirstError && !empty(siteErrors)) {
      return false;
    }
    const baselineErrors = ValidationService.validateBaselines(certification);
    if (stopAtFirstError && !empty(baselineErrors)) {
      return false;
    }
    const growthModelErrors =
      ValidationService.validateGrowthModel(certification);
    if (stopAtFirstError && !empty(growthModelErrors)) {
      return false;
    }
    let indicationErrors = null;
    if (!skipIndications) {
      const standard = StandardDefinitionService.findByVersion(
        certification.standardVersion
      );
      indicationErrors = ValidationService.validateIndications(
        certification,
        standard
      );
      if (stopAtFirstError && !empty(indicationErrors)) {
        return false;
      }
    }

    const errors = Object.assign(
      {},
      siteErrors,
      baselineErrors,
      growthModelErrors,
      indicationErrors
    );
    if (empty(errors)) {
      return stopAtFirstError ? true : null;
    } else {
      return errors;
    }
  }

  getIndicatorObject(standard: StandardDefinitionInterface) {
    const indicators = this.getIndicators(standard);
    const initial: { [key: string]: IndicatorInterface } = {};
    const indicatorObject = indicators.reduce(
      (prev, curr): { [key: string]: IndicatorInterface } => {
        prev[curr.identifier] = curr;
        return prev;
      },
      initial
    );
    return indicatorObject;
  }

  getIndicators(s: StandardDefinitionInterface): IndicatorInterface[] {
    const i = s.principles
      .map((p: PrincipleInterface) => [
        ...p.criterias.map((c: CriteriaInterface) => {
          const r = [...c.indicators];
          return r;
        }),
      ])
      .flatMap((i) => i)
      .flatMap((i) => i);
    return i;
  }

  static validateBaselines(
    certification: Certification
  ): CertificationValidationErrors | null {
    const errors: CertificationValidationErrors = {};

    // ocell method is not required to have baselines (for preview)
    if (certification.method === StandardMethod.IFM_DE_OCELL) {
      return null;
    }
    if (!certification?.baselines || certification?.baselines.length === 0) {
      errors['noBaselines'] = true;
    } else {
      certification.baselines.forEach((baseline) => {
        switch (certification.method) {
          case StandardMethod.AR: {
            if (
              baseline.inputId === null ||
              !baseline.co2Storage ||
              baseline.co2Storage === 0
            ) {
              if (errors.invalidBaselines === undefined) {
                errors['invalidBaselines'] = [];
              }
              errors['invalidBaselines'].push(baseline.name);
            }
            break;
          }
          case StandardMethod.IFM: {
            if (
              !baseline.co2Storage ||
              baseline.co2Storage === 0 ||
              !baseline.documents?.length
            ) {
              if (errors.invalidBaselines === undefined) {
                errors['invalidBaselines'] = [];
              }
              errors['invalidBaselines'].push(baseline.name);
            }
            break;
          }
        }
      });
    }
    if (!certification?.sites || certification?.sites.length === 0) {
      errors['noSites'] = true;
    } else {
      certification.sites.forEach((site) => {
        if (!site.baselineId) {
          if (!errors.unassignedSites) {
            errors.unassignedSites = [];
          }
          errors.unassignedSites.push(site.id);
        }
      });
    }

    if (Object.keys(errors)?.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  static validateGrowthModel(
    certification: Certification
  ): CertificationValidationErrors | null {
    const errors: CertificationValidationErrors = {};

    // ocell method is not required to have gm (for preview)
    if (certification.method === StandardMethod.IFM_DE_OCELL) {
      return null;
    }

    if (
      !certification?.growthModels ||
      certification?.growthModels.length === 0
    ) {
      errors['noGrowthModels'] = true;
    } else {
      certification.growthModels.forEach((growthModel) => {
        switch (certification.method) {
          case StandardMethod.AR: {
            if (
              growthModel.inputId === null ||
              !growthModel.co2Storage ||
              growthModel.co2Storage === 0
            ) {
              if (errors.invalidGrowthModels === undefined) {
                errors['invalidGrowthModels'] = [];
              }
              errors['invalidGrowthModels'].push(growthModel.name);
            }
            break;
          }
          case StandardMethod.IFM: {
            if (
              !growthModel.co2Storage ||
              growthModel.co2Storage === 0 ||
              !growthModel.documents?.length
            ) {
              if (errors.invalidGrowthModels === undefined) {
                errors['invalidGrowthModels'] = [];
              }
              errors['invalidGrowthModels'].push(growthModel.name);
            }
            break;
          }
        }
      });
    }
    if (!certification?.sites || certification?.sites.length === 0) {
      errors['noSites'] = true;
    } else {
      certification.sites.forEach((site) => {
        if (!site.growthModelId) {
          if (!errors.unassignedSites) {
            errors.unassignedSites = [];
          }
          errors.unassignedSites.push(site.id);
        }
      });
    }

    if (Object.keys(errors)?.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  /**
   * it depends on the role if a indication is valid
   * project manager => the "valid" flag of the indication holds the status
   *
   * auditor => the reviewed flag holds the status
   *
   * we don't want to show the valid status of indications that are
   * not of meaning for the current role
   *
   * ADMIN role should see the validations depending on
   * the current status
   *
   */
  static validateIndications(
    certification: Certification,
    standard?: StandardDefinitionInterface,
    role?: Role | undefined
  ): CertificationValidationErrors | null {
    if (!certification) {
      return { noCertification: true };
    }
    if (!standard) {
      standard = StandardDefinitionService.findByVersion(
        certification.standardVersion
      );
    }
    const errors: CertificationValidationErrors = {};
    if (
      !certification.indications?.length ||
      certification.indications?.length === 0
    ) {
      // should never happen, indications are created on certification creation
      errors['noIndications'] = true;
    } else {
      if (!role || role === Role.ADMIN) {
        // admins should see the validation status
        // according to the current certification process step
        role =
          certification.status &&
          (CertificationProcessService.isProjectManagerStatus(
            certification.status
          ) ||
            certification.status === CertificationStatus.PRECHECK_REQUESTED)
            ? Role.PROJECT_MANAGER
            : Role.AUDITOR;
      }
      if (role === Role.PROJECT_MANAGER || role === Role.CONSULTANT) {
        // Project Manager status  => all indications need to have a valid flag
        certification.indications?.forEach((i) => {
          if (
            !i.valid &&
            !ValidationService.isIndicationExcludedFromValidation(
              i,
              certification.standardVersion as StandardVersion,
              certification.groupId
            )
          ) {
            if (!errors.invalidIndications) {
              errors.invalidIndications = [];
            }
            errors.invalidIndications.push(i.indicatorId + ': ' + i.id);
          }
        });
      } else if (role === Role.AUDITOR) {
        // Auditor status => all indications need to have a reviewed flag
        const indicatorObject =
          StandardDefinitionService.getIndicatorsByVersion(standard.version);

        certification.indications.forEach((indication) => {
          if (
            indicatorObject &&
            indicatorObject[indication.indicatorId] &&
            indicatorObject[indication.indicatorId].auditorType ===
              AuditorType.AUDITOR &&
            indicatorObject[indication.indicatorId].inputType !==
              InputType.NA &&
            !indication.reviewed &&
            !ValidationService.isIndicationExcludedFromValidation(
              indication,
              certification.standardVersion as StandardVersion,
              certification.groupId
            )
          ) {
            if (!errors.invalidIndications) {
              errors.invalidIndications = [];
            }

            errors.invalidIndications.push(indication.id);
          }
        });
      }
    }

    if (Object.keys(errors)?.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  /**
   * validate hard coded Indication IDs
   */
  static isValidIndication(
    indication: IndicationInterface,
    certification: Certification
  ) {
    const version = StandardDefinitionService.getMajorVersion(
      certification.standardVersion
    );
    switch (indication.indicatorId) {
      // only first certification!!
      // project start not before 2021-09-30 (first WKS pre release)
      case version + '-1.3.1':
        return (
          certification.project?.startDate &&
          certification.project?.startDate >= new Date('2021-09-30') &&
          ValidationService.startDatesAreValid(certification)
        );
      case version + '-1.3.2':
        return (
          certification.creditingPeriod && certification.creditingPeriod > 0
        );
    }
    if (version !== '0.4') {
      switch (indication.indicatorId) {
        case version + '-1.3.3':
          return ValidationService.startDatesAreValid(certification);
        case version + '-1.3.4':
          return (
            certification.creditingPeriod && certification.creditingPeriod > 0
          );
        case version + '-1.3.5':
          return (
            certification.project?.startDate &&
            certification.project?.startDate >= new Date('2021-09-30') &&
            ValidationService.startDatesAreValid(certification)
          );
      }
    }
    const indicator = StandardDefinitionService.getIndicator(
      indication.indicatorId,
      version
    );
    if (indicator) {
      return ValidationService.isAlwaysValidIndication(indicator, version);
    }
    return false;
  }

  static validateAuditorRequests(
    certification: Certification
  ): CertificationValidationErrors | null {
    if (!certification.auditorRequests) {
      return null;
    }

    const auditorRequests = (certification.auditorRequests ??
      []) as AuditorRequestFragment[];
    let isValid = true;
    if (certification.status) {
      if (CertificationProcessService.isAuditorStatus(certification.status)) {
        isValid = auditorRequests.find((ar) => !ar.reviewed) === undefined;
      } else if (
        CertificationProcessService.isProjectManagerStatus(certification.status)
      ) {
        isValid =
          auditorRequests.find((ar) => {
            return (
              [VerificationStatus.CORRECTIVE_ACTION_REQUEST].includes(
                ar.verificationStatus
              ) && !ar.valid
            );
          }) === undefined;
      }
    }

    return isValid ? null : { invalidAuditorRequests: true };
  }

  /**
   * Returns true, if hard coded indicator is always valid by version
   */
  static isAlwaysValidIndication(
    indicator: IndicatorInterface,
    version: string
  ) {
    if (version === '0.4') {
      // InputType === NA
      switch (indicator.identifier) {
        // TODO: check by WKS AND/OR possibly uploaded document?
        case version + '-1.2.1':
          return true;
        // always true (Gesetze)
        case version + '-1.2.2':
          return true;
        // certification start is not known upfront
        // will be checked by WKS
        case version + '-1.3.3':
          return true;
        // TODO: Transparenz-Plattform außer sensible Projektinformationen
        case version + '-2.3.1':
          return true;
        // TODO: nicht öffentliche Infos
        case version + '-2.3.2':
          return true;
        // TODO: News veröffentlichen geht derzeit noch nicht
        case version + '-2.3.3':
          return true;
        // TODO: Finanzielle Additionalität Öffentliche Hand?
        case version + '-3.2.2':
          return true;
        // TODO: check Organisation→contact
        case version + '-6.1.2':
          return true;
        // valid recertification dates
        case version + '-8.2.2':
          return true;
        // valid standard version
        case version + '-8.2.3':
          return true;
        // TODO: certification not later than 6 month before first audit
        case version + '-8.2.4':
          return true;
        // Credits are published in registry
        case version + '-9.1.1':
          return true;
        case version + '-9.1.2':
          return true;
        case version + '-9.1.3':
          return true;

        default:
          if (indicator.inputType === InputType.NA) {
            return true;
          }
      }
    } else if (version === '1.0') {
      // InputType === NA
      switch (indicator.identifier) {
        // AGB are mandatory on login
        case version + '-1.2.6':
          return true;
        // certification start is not known upfront
        // will be checked by WKS
        case version + '-1.3.5':
          return true;
        // TODO: News veröffentlichen geht derzeit noch nicht
        case version + '-2.3.3':
          return true;
        // Hinweis Förderung durch Öffentliche Hand?
        case version + '-3.2.2':
          return true;
        // TODO: check Organisation→contact
        case version + '-5.1.2':
          return true;
        // Quantifizierung THG Bilanz
        case version + '-6.4.4':
          return true;
        // Quantifizierung THG Bilanz
        case version + '-6.5.3':
          return true;
        // Quantifizierung Klimazertifikate
        case version + '-6.7.1':
          return true;
        // valid recertification dates
        case version + '-8.2.2':
          return true;
        // valid standard version
        case version + '-8.2.3':
          return true;
        // TODO: certification not later than 6 month before first audit
        case version + '-8.2.4':
          return true;
        // Credits are published in registry
        case version + '-9.1.1':
          return true;
        case version + '-9.1.2':
          return true;
        case version + '-9.1.3':
          return true;

        default:
          if (
            indicator.inputType === InputType.NA ||
            indicator.inputType === undefined
          ) {
            return true;
          }
      }
    } else if (version === '1.1') {
      // InputType === NA
      switch (indicator.identifier) {
        // AGB are mandatory on login CHECK
        case version + '-1.2.5':
          return true;
        // certification start is not known upfront
        // will be checked by WKS
        case version + '-1.3.5':
          return true;
        // TODO: News veröffentlichen geht derzeit noch nicht
        case version + '-2.3.3':
          return true;
        // Hinweis Förderung durch Öffentliche Hand
        case version + '-3.2.2':
          return true;
        // TODO: check Organisation→contact
        case version + '-5.1.2':
          return true;
        // Quantifizierung THG Bilanz
        case version + '-6.5.5':
          return true;
        // Quantifizierung THG Bilanz
        case version + '-6.6.3':
          return true;
        // Quantifizierung Klimazertifikate
        case version + '-6.8.1':
          return true;
        // valid recertification dates
        case version + '-8.2.2':
          return true;
        // valid standard version
        case version + '-8.2.3':
          return true;
        // TODO: certification not later than 6 month before first audit
        case version + '-8.2.4':
          return true;
        // Credits are published in registry
        case version + '-9.1.1':
          return true;
        case version + '-9.1.2':
          return true;
        case version + '-9.1.3':
          return true;

        default:
          if (
            indicator.inputType === InputType.NA ||
            indicator.inputType === undefined
          ) {
            return true;
          }
      }
    }
    return false;
  }

  // **
  //  * check if certification dates are valid
  static validDates(certification: Certification): boolean {
    //Uhrzeit=(0uhr)
    const startTime = new Date(
      new Date(certification.certificationStartDate).toDateString()
    ).getTime();

    const endTime = new Date(certification.certificationCompleteDate).getTime();
    const retVal =
      certification.certificationStartDate &&
      startTime >=
        new Date(new Date(certification.createdAt).toDateString()).getTime() &&
      certification.certificationCompleteDate &&
      endTime > startTime;
    return retVal;
  }

  /**
   * crediting period may not start before project startdate
   */
  static startDatesAreValid(certification: Certification): boolean {
    const creditingStartDate = certification.creditingPeriodStart;
    const projectStartDate = certification.project?.startDate;
    if (!creditingStartDate || !projectStartDate) {
      return false;
    }
    return creditingStartDate >= projectStartDate;
  }

  static isValidIndicationInput(indication: IndicationInterface): boolean {
    if (!indication.indicator) {
      throw new Error('No indicator found in indication');
    }
    if (indication.indicator.inputType !== InputType.INDICATION) {
      throw new Error(
        'Only Indicators with inputType "INDICATION" can be validated!'
      );
    }
    if (
      !indication.indicator.evidenceProvidedBy ||
      !(indication.indicator.evidenceProvidedBy.length > 0)
    ) {
      throw new Error(
        'Indicator with empty evidenceProvidedBy can\t be validated!'
      );
    }

    const evidenceProvidedBy = indication.indicator.evidenceProvidedBy;

    if (
      evidenceProvidedBy.includes(EvidenceType.PRE_CERTIFICATION) ||
      evidenceProvidedBy.includes(EvidenceType.ADDITIONALITY_TOOL)
    ) {
      return !((indication.documents ?? []).length === 0);
    }

    if (evidenceProvidedBy.includes(EvidenceType.DEFAULTS_MANDATORY_TEXT)) {
      return indication.confirmed && indication.text.length >= 20;
    }
    if (
      evidenceProvidedBy.includes(EvidenceType.CONFIRM) ||
      evidenceProvidedBy.includes(EvidenceType.DEFAULTS_OPTIONAL_TEXT)
    ) {
      return indication.confirmed;
    } else if (evidenceProvidedBy.includes(EvidenceType.MANDATORY_TEXT)) {
      return indication.text.length >= 20;
    }

    return false;
  }

  /**
   * Returns a object of all group dependent indicators by standard version
   *
   * @param standardVersion Standard version
   * @returns Object of all group dependent indicators
   */
  static getGroupDependentIndicators = (standardVersion: StandardVersion) => {
    if (standardVersion === '1.1') {
      return {
        GROUP_CERTIFICATION: standardVersion + '-8.2.5',
      };
    }

    return {};
  };

  /**
   * Validates hard coded Indication IDs for validation exclusion
   *
   * @param certification Certification object
   * @param indication Indication object
   * @returns returns true, if indication is excluded from indication validation
   */
  static isIndicationExcludedFromValidation(
    indication: IndicationInterface | IndicationFragment,
    standardVersion: StandardVersion,
    groupId?: string | null
  ): boolean {
    if (standardVersion === '1.1') {
      switch (indication.indicatorId) {
        // Group Certification Indicator excluded, when certification is not in a group certification
        case ValidationService.getGroupDependentIndicators(standardVersion)
          .GROUP_CERTIFICATION:
          return !groupId;

        default:
          return false;
      }
    }

    return false;
  }
}
